const header = document.querySelector('header.site-header');
document.querySelector('.menuTrigger').addEventListener('click', function(e) {
  header.classList.toggle('is-open');
});

const menuScrollLinks = document.querySelectorAll('ul.menu li.scroll a');
if (menuScrollLinks) {
    menuScrollLinks.forEach(function(menuScrollLink) {
        menuScrollLink.addEventListener('click', function() {
            header.classList.remove('is-open');
        })
    })
}

document.querySelector('body').classList.remove('preload');

// set up video sources
function setResponsiveVideo() {
    let videos = document.querySelectorAll('video');
    if (videos) {
        videos.forEach((video) => {
            if (window.innerWidth < 800) {
                video.setAttribute('src', video.dataset.src_mobile);
            } else {
                video.setAttribute('src', video.dataset.src);
            }
        })
    }
}
setResponsiveVideo();

