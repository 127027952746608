// Sass index file
import './sass/styles.scss';

// Load js modules
import './js/owl';
import './js/parallax';

//import './js/slider';
import './js/aos';
import './js/modal';
import './js/main';
import './js/main.jquery';

// Load theme specific modules
//import './js/theme-switcher';
//import './js/numbers';
//import './js/masonry';
import './js/theme';

//import './js/scrolly';


