if (document.querySelector('.scroll-down')) {
    document.querySelector('.scroll-down').addEventListener('click', function () {
        const y = document.getElementById('scrolly').getBoundingClientRect().top + window.scrollY;
        window.scroll({
            top: y - 350,
            behavior: 'smooth'
        });
        this.style.display = 'none';
    })

}

var idx = 1;
const totalWords = document.querySelectorAll('.change_text span').length;
function scrollText() {

    if (idx > totalWords) {
        idx = 1;
    }
    jQuery('.change_text span').removeClass('active').hide();
    jQuery('.change_text span:nth-of-type(' + idx +')').show().addClass('active');
    idx++;
    setTimeout(() => {
        scrollText();

    }, 2000);
}
scrollText();


jQuery('.portfolio .post__item .post__link').on('click', function() {
    document.getElementById('modal-project').querySelector('.modal__content').innerHTML = this.querySelector('.details').innerHTML;


    jQuery('.portfolio-gallery.owl-carousel').owlCarousel('destroy');

    if (document.getElementById('modal-project').querySelector('.modal__content .portfolio-gallery')) {
        let portfolioCarousel = jQuery('.portfolio-gallery.owl-carousel');
        portfolioCarousel.owlCarousel({
            loop:true,
            nav:true,
            dots:false,
            items: 1,
            stagePadding: 0,
            lazyLoad: false,
            responsiveClass:false,
            responsiveBaseElement: 'body',
            margin: 24,
            responsive: {
                1200: {
                    items: 2,
                    margin: 40
                },
            }
        });
    }

})
