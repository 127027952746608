// if you really need to use jQuery
jQuery(document).ready(function ($) {

    $('.img-popup').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }

    });


    $('.gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr('title');
            }
        }
    });

    let testimonialsCarousel = $('.testimonials .owl-carousel');
    let perView = testimonialsCarousel.data('per_page') ? testimonialsCarousel.data('per_page') : 1;
    testimonialsCarousel.owlCarousel({
        loop:true,
        nav: false,
        dots:false,
        items: 1,
        stagePadding: 0,
        lazyLoad: false,
        responsiveClass:false,
        responsiveBaseElement: 'body',
        autoHeight: true,
        margin: 24,
        animateIn: 'fadeIn',
        slideSpeed: 0,
        paginationSpeed: 0,
        dots: true,
        responsive: {
            1200: {
                items: perView,
                margin: 80,
                nav:true
            },
        }
    });

    function initSectionSliders()
    {
        let sectionSliders = $('.section-slider .slider');
        if (sectionSliders) {

            sectionSliders.each((idx, slider) => {
                if (window.innerWidth > 600) {
                    $(slider).addClass('owl-carousel');
                    let perView = $(slider).data('per_page') ? $(slider).data('per_page'): 1;
                    $(slider).owlCarousel({
                        loop:true,
                        dots:false,
                        nav:true,
                        items: 1,
                        stagePadding: 0,
                        lazyLoad: false,
                        responsiveClass:false,
                        responsiveBaseElement: 'body',
                        margin: 24,
                        responsive: {
                            1200: {
                                items: perView,
                                margin: 64
                            },
                        }
                    });
                } else {
                    if ($(slider).hasClass('owl-carousel')) {
                        $(slider).data('owl.carousel').destroy();
                        $(slider).removeClass('owl-carousel');
                    }
                }

            })
        }
    }

    initSectionSliders();
    window.addEventListener('resize', initSectionSliders);

    function addOwlAriaLabels() {
        $('.owl-carousel').each(function() {
            $(this).find('.owl-dot').each(function(index) {
                $(this).attr('aria-label', 'slide ' + (parseInt(index) + 1));
            });
        });
    }
    addOwlAriaLabels();

    $("a[href='#top']").click(function(e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    if (document.cookie.indexOf('cookiePreference=accepted') > -1) {
        $('#cookie-modal').remove();
    } else{
        setTimeout(function() {
            $('#cookie-modal').addClass("is-visible");
        }, 2500);
    }
    $('#accept').click(function(){
        document.cookie = 'cookiePreference=accepted; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
        $('#cookie-modal').removeClass("is-visible");
    });

    $('form button').on('click', function(e) {
        $(this).closest('form').addClass('submit-attempt');
        $('form label').removeClass('invalid');
        $('form input:invalid').closest('label').addClass('invalid');
    })
    $('form.contact').on('submit', function(e) {
        let url = site_url + '/contact.php';
        this.classList.add('submit-attempt')
        $.post(
            url,
            $(this).serialize(),
            function(res){
                let msg = $('.contact-block  .success').html();
                $('.contact-block .text-col').html(msg);
                window.scroll({
                    top: 0,
                    behavior: 'smooth'
                });

                var countdownEl = jQuery('.continue span');
                if (countdownEl.length) {
                    var timeleft = 4;
                    var countdownTimer = setInterval(function(){
                        if(timeleft <= 0){
                            clearInterval(countdownTimer);
                            window.location.href = site_url;
                        }
                        countdownEl.text(timeleft)
                        timeleft -= 1;
                    }, 1000);
                }
            }
        );
        return false;
    })

    $(document).on('submit', 'form.feedback', function(e) {
        let url = site_url + '/contact.php';
        e.preventDefault();
        $.post(
            url,
            $(this).serialize(),
            function(res){
                alert(txt_msg_sent);
                $('.modal').attr('open', false);
            }
        );
        return false;
    })

});
